import tippy from 'tippy.js';

export function initializeTooltips(el) {
    const base = el ?? document;

    base.querySelectorAll('[data-toggle="tooltip"]').forEach(node => {
        tippy(node, {
            content: node.getAttribute('data-title') || node.getAttribute('title'),
        });
    });
}

initializeTooltips();
